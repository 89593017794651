<template>
    <div id="governorates">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="11">
                    <b-form-input
                    style="width: 20%;"
                      type="text"
                      :placeholder="$t('Trans No')"
                      v-model="filterData.trans_no"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col cols="1">
                    <template>
                        <b-button style="float: right;" variant="primary" @click="addInit()" size="sm">{{ $t('تسديد') }}</b-button>
                    </template>
                  </b-col>
                  <!-- <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('Ref')"
                      v-model="filterData.ref"
                      required
                    ></b-form-input>
                  </b-col> -->
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- section create invoice -->
        <div class="add-section">
            <b-modal id="invoice-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('sidebar.feature')" @ok="submit">
                <b-form class="d-grid gap-5">
                <div class="feild">
                    <label for="">{{ $t('customer name') }}</label>
                    <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.customer_name"
                    required
                    ></b-form-input>
                </div>
                <div class="feild">
                    <label for="">{{ $t('customer phone') }}</label>
                    <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.customer_phone"
                    required
                    ></b-form-input>
                </div>
                <div class="feild">
                    <label for="">{{ $t('amount') }}</label>
                    <b-form-input
                    type="text"
                    placeholder=""
                    v-model="payload.amount"
                    required
                    ></b-form-input>
                </div>
                </b-form>
            </b-modal>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>

            <template v-slot:headerTitle>
                <b-row style="width: 200%;font-size: 1rem;">
                    <b-col cols="6">
                        <b>Account-name </b> <span>: {{ hotel.title }}</span>
                    </b-col>
                    <b-col cols="6">
                        <b>Account-ref </b> <span>: {{ hotel.code }}</span>
                    </b-col>
                </b-row>
                <b-row style="width: 200%;font-size: 1rem;">
                    <b-col cols="6">
                        <b>Address </b> <span>: {{ hotel.address }}</span>
                    </b-col>
                    <b-col cols="6">
                        <b>Account-name </b> <span>: {{ hotel.title }}</span>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:body>
              <b-table responsive :items="hotelTransactions.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="{item}">
                    <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                      <b-button v-if="item.creator" v-b-tooltip.top="$t('show-invoice')" variant=" iq-bg-info" @click="showInvoice(item.id)"  size="md">
                        <i class="ri-chat-check-line"></i>
                      </b-button>
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="hotelTransactions.total - filterData.take"
                  :per-page="filterData.take"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
import debounce from 'debounce'

export default {
  name: 'Hotel-Transactions',
  data () {
    return {
    payload: {
        customer_name: '',
        customer_phone: '',
        amount: '',
        hotel_id: this.$route.params.id,
    },
      filterData: {
        page: 0,
        take: 15,
        trans_no: "",
        ref: "",
        model_id: this.$route.params.id,
        model_type: 'hotel',
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('date'),
          key: 'date'
        },
        {
          label: this.$t('trans_no'),
          key: 'trans_no'
        },
        {
          label: this.$t('trans_name'),
          key: 'trans_name'
        },
        {
          label: this.$t('ref'),
          key: 'ref'
        },
        {
          label: this.$t('percentage'),
          key: 'percentage'
        },
        {
          label: this.$t('res_amount'),
          key: 'res_amount'
        },
        {
          label: this.$t('markup'),
          key: 'markup'
        },
        {
          label: this.$t('percentage'),
          key: 'percentage'
        },
        {
          label: this.$t('commission'),
          key: 'commission'
        },
        {
          label: this.$t('depit'),
          key: 'depit'
        },
        {
          label: this.$t('credit'),
          key: 'credit'
        },
        {
          label: this.$t('balance'),
          key: 'balance'
        },
        {
          label: this.$t('created-by'),
          key: 'creator.name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        },
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 0
      this.$store.dispatch('getHotelTransactionList', this.filterData)
    },
    showInvoice (id) {
      this.$router.push({ name: 'transaction-invoice', params: { id } })
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    },
    addInit () {
      this.$bvModal.show('invoice-modal')
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
        this.addHotelInvoice(this.payload).then(() => {
          this.$bvModal.hide('invoice-modal')
          this.payload = this.resetObject(this.payload)
          this.$store.dispatch('getHotelTransactionList', this.filterData)
        })
        //sleep in js for 1 second
        setTimeout(() => {
            window.location.reload();
        }, 500);
    },
    handleHotelSearch: debounce(function (title) {
      if (!title || title.length < 1) return
      this.getHotels(this.filterData)
    }, 1000)
  },
  watch: {
    filterData: {
      handler: function (value) {
        // this.$store.dispatch('getHotelTransactionList', this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getHotel(this.$route.params.id)
    this.$store.dispatch('getHotelTransactionList', this.filterData)
  }
}
</script>
